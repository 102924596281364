@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable.css");
/* @import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap"); */

@font-face {
  font-family: "Reenie Beanie";
  src: url("/public/assets/fonts/ReenieBeanie-Regular.ttf") format("truetype"); /* 경로는 당신의 .ttf 파일의 위치에 따라 다를 수 있습니다. */
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* 폰트 변경해야 함 */
}

html {
  font-size: 0.9225vw;
}

@media screen and (min-width: 480px) {
  html {
    font-size: 4.428px;
  }
}

/* @media screen and (min-width: 1084px) {
  html {
    font-size: 10px;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.left-minus-50 {
  left: -50%;
}

input {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline;
  vertical-align: middle;
  line-height: normal;
  outline: none;
  background-color: transparent;
  caret-color: #fd33da;
}

input::placeholder {
  color: #afa100;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input.message {
  color: #ffffff;
  caret-color: #ffffff;
}

input.message::placeholder {
  color: #c8c8c8;
}
